<template>
  <div>
    <div class="card">
      <div class="card-header">
        <h4 class="card-header-title">{{$t('components.partners.settlementOfPartners')}}</h4>
        <router-link
          :to="'/partnersSettlements/create?partner_id=' + $route.params.id"
          class="btn btn-sm btn-primary"
        >
          <i class="fas fa-plus"></i> {{$t('components.partners.addition')}}
        </router-link>
      </div>
      <indexTable
        :searchText="$t('partnersSettlements.SearchForAProcess')"
        :emptyTableText="
          $t('partnersSettlements.ThereAreCurrentlyNoSettlements')
        "
        :filter="{partner_id: $route.params.id}"
        :emptyTableSubText="$t('partnersSettlements.TryAddingSomeAndTryAgain')"
        :withoutCard="true"
        :cloumns="[
          {
            column: 'code',
            title: $t('partnersSettlements.operation'),
            type: 'mainLink',
            sort: true,
          },
          {
            column: 'safe_id',
            title: $t('partnersSettlements.Project'),
            type: 'link',
            to: 'project',
            sort: true,
            link: true,
          },
          {
            column: 'date',
            title: $t('partnersSettlements.date'),
            type: 'text',
            sort: true,
          },
          {
            column: 'total',
            title: $t('partnersSettlements.cost'),
            type: 'text',
            sort: true,
          },
          {
            column: 'description',
            title: $t('partnersSettlements.thatAbout'),
            type: 'text',
            sort: true,
          },
          {
            column: 'options',
            title: $t('partnersSettlements.settings'),
            type: 'options',
            options: [
              { name: 'show' },
              {
                name: 'edit',
                role:
                  $user.admin ||
                  $user.role.safes_deposits_edit,
              },
              {
                name: 'delete',
                role:
                  $user.admin ||
                  $user.role.safes_deposits_delete,
              },
            ],
          },
        ]"
        :deleteText="{
          attention: $t('allerts.Attention'),
          areYouReallySureToDeleteTheItem: $t(
            'allerts.areYouReallySureToDeleteTheItem'
          ),
          close: $t('allerts.close'),
          confirm: $t('allerts.confirm'),
          loading: $t('allerts.loading'),
        }"
      />
    </div>
  </div>
</template>
<script>
import indexTable from "../../elements/index/indexTable.vue";

export default {
  data() {
    return {
      path: "/partnersSettlements",
    };
  },
  components: {
    indexTable,
  },
};
</script>
