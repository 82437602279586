<template>
  <div class="container-fluid">
    <div class="row">
      <showHeader
        class="col-12 mt-3"
        :image="item.image"
        :icon="'user'"
        :title="item.name"
        :stat="item.stat"
        :statText="
          item.stat ? $t('partners.show.active') : $t('partners.show.inactive')
        "
        :buttons="[
          {
            title: $t('partners.show.modulation'),
            icon: 'edit',
            link: path + '/' + $route.params.id + '/edit',
            role: $user.admin || $user.role.partners_edit,
          },
          {
            title: $t('partners.show.ComprehensiveReport'),
            icon: 'file-chart-pie',
            link: '/partnersDetailsAccounts?partner_id=' + $route.params.id,
            role: $user.admin || $user.role.reports_show,
          },
        ]"
      />

      <div class="col-12">
        <showFile />
      </div>

      <showCard
        class="col-12"
        :title="$t('partners.show.Background')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'user',
              title: $t('partners.show.partnerName'),
              value: item.name,
            },
            {
              icon: 'dollar-sign',
              title: $t('partners.show.Balance'),
              value: $moneyFormat(( $option.balance_type == 1 ? item.balance * -1 : item.balance )),
            },
          ],
          scondCloum: [
            {
              icon: 'dollar-sign',
              title: $t('partners.show.OpeningBalance'),
              value: $moneyFormat(item.opening_balance),
            },
            {
              icon: 'toggle-on',
              title: $t('partners.show.partnerStatus'),
              value:
                item.stat == 1
                  ? $t('partners.show.effective')
                  : $t('partners.show.Ineffective'),
            },
          ],
        }"
      />

      <showCard
        class="col-12"
        :title="$t('partners.show.BillingAddress')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('partners.show.Address'),
              value: item.address1,
            },
            {
              icon: 'location-arrow',
              title: $t('partners.show.TheSide'),
              value: item.entity,
            },
            {
              icon: 'map-marked',
              title: $t('partners.show.ZipCode'),
              value: item.zip,
            },
          ],
          scondCloum: [
            {
              icon: 'map-marker-alt',
              title: $t('partners.show.TheSequel'),
              value: item.address2,
            },
            { icon: 'city', title: $t('partners.show.City'), value: item.city },
            {
              icon: 'globe-americas',
              title: $t('partners.show.State'),
              value: item.country,
            },
          ],
        }"
      />

      <showCard
        class="col-12"
        :title="$t('partners.show.CommunicationInformation')"
        :cloumns="{
          firstCloum: [
            {
              icon: 'phone',
              title: $t('partners.show.MobileNumber'),
              value: item.mobile,
            },
          ],
          scondCloum: [
            { icon: 'at', title: $t('partners.show.Email'), value: item.email },
          ],
        }"
      />

      <partnerDeposit class="col-12" v-if="$site.partnersDeposits_allow && ($user.admin || $user.role.partners_deposits_show)"/>
      <partnerWithdrawal class="col-12" v-if="$site.partnersWithdrawals_allow && ($user.admin || $user.role.partners_withdrawals_show)"/>
      <partnerSettlement class="col-12" v-if="$site.partnersSettlements_allow && ($user.admin || $user.role.partners_settlements_show)"/>
      <partnerProfit class="col-12" v-if="$site.partnersProfits_allow && ($user.admin || $user.role.partners_profits_show)"/>
    </div>
  </div>
</template>
<script>
import axios from "axios";

import showHeader from "../elements/show/showHeader.vue";
import showCard from "../elements/show/showCard.vue";
import showBoxs from "../elements/show/showBoxs.vue";

import partnerDeposit from "./components/partnerDeposit.vue";
import partnerWithdrawal from "./components/partnerWithdrawal.vue";
import partnerSettlement from "./components/partnerSettlement.vue";
import partnerProfit from "./components/partnerProfit.vue";

import showFile from '../elements/show/showFile.vue';

export default {
  data() {
    return {
      path: "/partners",
      loaded: false,
      item: {},
    };
  },
  mounted() {
    this.getitem();
  },
  methods: {
    getitem() {
      var item = this.$database.partners.find(
        (el) => el.id == this.$route.params.id
      );
      if (item) {
        this.item = item;
      }
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          this.item = response.data;
          this.loaded = true;
        });
    },
  },
  components: {
    showHeader,
    showCard,
    showBoxs,
    partnerDeposit,
    partnerWithdrawal,
    partnerSettlement,
    partnerProfit,
    showFile
  },
};
</script>
